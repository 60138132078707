<template>
    <div id="suncoast-safety-feedback-form"  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'">
            <form @submit.prevent="updateRecord" class="">
                <div class="do-not-print-me bottom-40">
                    <div class="flex space-between">
                        <div class="name-id"><FormClient :client_id="client_id" @getClient="getClient" /><br /></div>
                        <div class="align-right">
                            <FormCompany @getCompany="getCompany" />
                        </div>
                    </div>
                    <div>
                        <h2 class="bottom-10">{{ pageMeta.title }}</h2>
                    </div>
                    <button
                        type="button"
                        @click="signature_ids = []"
                        v-if="signature_ids.length && original_signature_ids.length"
                    >
                        Edit & Create Addendum
                    </button>
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                        You are now editing the original signed document to create an addendum
                    </div>
                    <hr class="m-15" />
                </div>

                <!-- put generated code BELOW here border:1px orange dashed; -->
                <div class="the-print-edge suncoast-safety-form" style="display: block; min-height: 984px; max-height: 1024px;">
                    <div class="bottom-15">
                        <h2 class="align-center bottom-10 " style="font-size: 14px !important;">Suncoast Safety Council, Inc.</h2>

                        <h2 class="align-center bottom-20 " style="font-size: 14px !important;">1145 Court Street Clearwater, FL 33756 &nbsp; (727) 443-0233 &nbsp; Fax (727) 447-1677</h2>
                        <h2 class="align-center bottom-20 " style="font-size: 18px !important;">Feedback</h2>
                    </div>

                    <div class="flex client-info">
                        <div class="flex bottom flex-1 right-20">
                            <label class="right-10 ">Client Name&nbsp;&nbsp;</label>
                            <input class="flex-1 " type="text" :value="client_name()" />
                        </div>
                        <div class="flex bottom flex-1">
                            <label class="right-10 ">D.O.B.&nbsp;&nbsp;</label>
                            <input class="flex-1 " type="text" :value="dayt(client.dob)" />
                        </div>
                    </div>

                    <div class="dark-text top-10 bottom-20 max900 " style="max-width: 630px;">
                        The above named client has been referred to our agency. We will assist you in tracking this
                        client’s progress by checking the appropriate section below. A copy will be sent to the
                        DUI program in 25 days of receipt of this form and at such time there is a change in the
                        client’s status.
                    </div>

                    <div class="flex checkbox-row">
                        <Checkbox
                            class="fake-checkbox bottom-0"
                            v-model="rec.contact_agency"
                            label="Client did not contact our agency."
                        />
                        <hr class="long-line short">
                    </div>

                    <div class="flex checkbox-row">
                        <Checkbox
                            class="fake-checkbox bottom-0 margin-0"
                            v-model="rec.client_appt_sched"
                            label="Client scheduled appointment within 20 days for"
                        />
                        <Input class=" margin-0" :class="(rec.appt_sched_date == '') ? 'do-not-print-me' : ''" type="date" v-model="rec.appt_sched_date" label="" />
                        <hr class="long-line">
                    </div>

                    <div class="flex checkbox-row">
                        <Checkbox
                            class="fake-checkbox bottom-0"
                            v-model="rec.client_kept_appt"
                            label="Client kept initial appointment on"
                        />
                        <Input class=" margin-0" :class="(rec.appt_kept_date == '') ? 'do-not-print-me' : ''" type="date" v-model="rec.appt_kept_date" label="" />
                        <hr class="long-line">
                    </div>

                    <div class="flex checkbox-row">
                        <Checkbox
                            class="fake-checkbox bottom-0"
                            v-model="rec.client_failed_appt"
                            label="Client failed to keep initial appointment on"
                        />
                        <Input class=" margin-0" :class="(rec.appt_failed_date == '') ? 'do-not-print-me' : ''" type="date" v-model="rec.appt_failed_date" label="" />
                        <hr class="long-line">
                    </div>

                    <div class="flex center checkbox-row longest">
                        <Checkbox
                            class="fake-checkbox bottom-0"
                            v-model="rec.client_entered_tx"
                            label="Client entered treatment on"
                        />
                        <div class="entered-treatment-date" style="width: 155px;">
                            <Input class=" margin-0" :class="(rec.enter_tx_start_date == '') ? 'do-not-print-me' : ''" type="date" v-model="rec.enter_tx_start_date" label="" />
                        </div>
                        <label >and will continue until</label>
                        <Input style ="width: 155px;" class="margin-0" :class="(rec.enter_tx_end_date == '') ? 'do-not-print-me' : ''" type="date" v-model="rec.enter_tx_end_date" label="" />
                        <hr class="long-line">
                    </div>

                    <div class="flex checkbox-row longest" id="last_contact_div">
                        <Checkbox
                            class="fake-checkbox bottom-0 "
                            v-model="rec.client_attended"
                            label="Client attended"
                        />
                        <Input
                            :input_style="'max-width:20px;'"
                            :id="'sessions_attended'"
                            class="rev-order bottom-0 client-attended"
                            type="text"
                            v-model="rec.sessions_attended"
                            label=" Sessions before abandoning treatment."
                        />
                        <label>&nbsp;Last contact was</label>
                        <Input
                            :input_style="'margin:0; color=red; width: 133px;'"
                            :id="'last_contact_date'"
                            type="date"
                            v-model="rec.last_contact_date"
                            label=""
                            class="bottom-0 "
                            :class="(rec.last_contact_date == '') ? 'do-not-print-me' : ''"
                        />
                        <hr class="long-line">
                    </div>

                    <div class="flex center checkbox-row">
                        <Checkbox
                            class="fake-checkbox bottom-0"
                            v-model="rec.client_reentered_tx"
                            label="Client re-entered treatment on"
                        />
                        <hr class="long-line short">
                        <Input class=" margin-0" :class="(rec.reenter_tx_start_date == '') ? 'do-not-print-me' : ''" type="date" v-model="rec.reenter_tx_start_date" label="" />
                        <hr class="long-line">
                    </div>

                    <div class="relative checkbox-row">
                        <Checkbox
                            class="fake-checkbox bottom-0"
                            v-model="rec.txplan"
                            label="Individual treatment has been formulated with this client."
                        />
                        <hr class="long-line short">
                    </div>

                    <div class="relative checkbox-row">
                        <Checkbox
                            class="block fake-checkbox bottom-0"
                            v-model="rec.txplan_alcohol_drugs"
                            label="The plan addresses alcohol or other drug issues as the major focus of treatment."
                        />
                        <hr class="long-line short">
                    </div>

                    <div class="relative checkbox-row">
                        <Checkbox
                            class=" block fake-checkbox bottom-0"
                            v-model="rec.client_progressing"
                            label="Client is progressing satisfactorily."
                        />
                        <hr class="long-line short">
                    </div>

                    <div class="relative checkbox-row">
                        <Checkbox
                            class="block fake-checkbox bottom-0"
                            v-model="rec.client_complete_tx"
                            label="Client completed treatment successfully."
                        />
                        <hr class="long-line short">
                    </div>

                    <div class="relative checkbox-row">
                        <Checkbox
                            class="block fake-checkbox bottom-0"
                            v-model="rec.aftercare"
                            label="Client was recommended or required to attend aftercare."
                        />
                        <hr class="long-line short">
                    </div>

                    <div class="comments-wrapper top-5 bottom-15">
                        <div class="comments-label">Comments:</div>
                        <div class="comment-lines">
                            <hr />
                            <hr />
                            <hr />
                            <hr />
                        </div>
                        <TextArea
                            class="comments dark-text weight-600 fullwidth"
                            type="text"
                            v-model="rec.comments"
                            label=""
                        />
                    </div>

                    <div class="tx-provider bottom-20">
                        <div class="provider bottom-10">Treatment Provider</div>
                        <p class="flex bottom">
                            <span class="provider-label">Agency</span>
                            <span class="flex-1">{{ company.company_name }}</span>
                        </p>
                        <p class="flex bottom">
                            <span class="provider-label">Address</span>
                            <span class="flex-1"
                                >{{ company.address }}, {{ company.city }}, {{ company.zip }}</span
                            >
                        </p>
                        <p class="flex bottom">
                            <span class="provider-label">Phone</span>
                            <span class="flex-1">{{ phone2oldphone(company.phone) }} &nbsp;&nbsp; Fax: {{ phone2oldphone(fax) }}</span>
                        </p>
                        <p class="flex bottom">
                            <span class="provider-label">Signature and Title</span>
                            <span v-if="signatures_info.length" class="flex-1"
                                ><img
                                    v-if="signatures_info[0].file"
                                    :src="`data:image/png;base64, ${signatures_info[0].file.Body}`"
                                    style="height: 20px;"
                                />
                                {{ name(signatures_info[0]) }}</span
                            >
                        </p>
                        <p class="flex bottom">
                            <span class="provider-label">Date</span>
                            <span v-if="signature_ids.length" style="padding-right: 20px;">{{ time(signature_ids[0].signedAt) }}</span>
                        </p>
                    </div>
                    <div class="footer-container fullwidth flex center space-between">
                        <div class="align-left"><strong style="font-family: Arial !important; font-size: 12px !important;">HSMV 77031 (Rev. 1/7/97)</strong></div>
                        <div class="align-right"><strong style="font-family: Arial !important;">{{ client.external_id }}</strong></div>
                    </div>
                </div>
                <!-- put generated code ABOVE here -->

                <div class="align-right do-not-print-me flex space-between" v-if="!signature_ids.length" style="max-width: 708px;">
                    <FormSignatureButton
                        :rec="rec"
                        :original_rec="original_rec"
                        :signatures_info="signatures_info"
                        :fileName="pageMeta.component_name"
                        :signature_ids="signature_ids"
                        :record_id="record_id"
                        @resetView="reloadData"
                        @fail="fail2sign"
                        @showModal="showPdfModal"
                        :save="updateRecord"
                        />
                    <div class="flex">
                        <button
                            v-if="original_signature_ids.length && !signature_ids.length"
                            class="align-right secondary inline-block"
                            type="button"
                            @click="signature_ids = original_signature_ids"
                            >Cancel Edit</button>
                        
                        <ConfirmButton class="left-15 save-btn" @click="updateRecord()" :canedit="isEditable" />
                    </div>
                </div>
            </form>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';
    import dayjs from '@/util/dayjs';
    import { signatures } from '@/util/apiRequests';

    export default {
        name: 'SuncoastSafety',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: [],
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,
                fax: '727-295-1001',
            };
        },
        methods: {
            phone2oldphone(str) {
                if (str) {
                    str = str.replace(/\D/g,"").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                }
                return str
            },
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
                await this.getSignatures();
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            async getSignatures() {
                if (this.signature_ids) {
                    try {
                        //This will retrieve the signatures, with the s3 data I need
                        const res = await Promise.all(
                            this.signature_ids.map((element) =>
                                this.$api.get(signatures.getSignature(element.id), { params: { t: element.type } })
                            )
                        );
                        let data = [];
                        this.signatures_info = res.map((element) => {
                            data = element.data;
                        });
                        this.signatures_info = [data];
                    } catch (error) {
                        //
                    }
                }
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                await this.getSignatures();
                this.loading = 0;
            },
            name(signature) {
                return `${signature.first_name ?? ''} ${signature.last_name ?? ''}`.trim();
            },
            client_name() {
                return `${this.client.first_name ?? ''} ${this.client.middle_name ? this.client.middle_name.charAt(0) + '.' : ''} ${this.client.last_name ?? ''}`.trim();
            },
            role(signature) {
                if (signature.role) {
                    return `(${signature.role})`.trim();
                }
                if (!signature.role && signature.sig_type == 'client') {
                    return '(client)';
                }
                return '';
            },
            time(t) {
                if (typeof t !== 'undefined') {
                    return dayjs(t).format('MM/DD/YYYY h:mma');
                }
                return '';
            },
            dayt(t) {
                if (typeof t !== 'undefined') {
                    return dayjs(t).format('MM/DD/YYYY');
                }
                return '';
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>

<style scoped>
    div.suncoast-safety div.the-print-edge {
        max-width: 660px;
    }
    @media print {
        div.suncoast-safety body,
        input#sidebar-expand,
        div#main-layout {
            display: none;
        }
        div.suncoast-safety div.the-print-edge {
            min-height: 960px;
        }
        div.suncoast-safety div.footer-container {
            position: absolute;
        }     
        div.suncoast-safety div.the-print-edge h2 {
            font-size: 14px;
            font-weight: bold;
        }
        div.suncoast-safety div.the-print-edge span.fake-checkbox {
            display: block;
        }
        div.suncoast-safety div.the-print-edge input#sessions_attended {
            width: 30px;
        }
        div.suncoast-safety .checkbox-row,
        div.suncoast-safety form {
            margin: 0;
        }
    }
</style>